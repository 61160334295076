:root {
  --star-size: 18px;
  --star-color: #525f7f;
  --star-background: #f8b704;
}

.star-rating-preview {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 25px;

  &::before {
    content: '⭐⭐⭐⭐⭐';
    letter-spacing: 6px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.small {
    font-size: 15px;
  }
}

.rating-desc {
  .progress {
    height: 13px;
    margin: 0.44rem 0;
  }
  .progress-bar {
    background-color: var(--star-background);
  }
  .stars-row:hover > div {
    font-weight: 600;
  }
  .stars-row {
    cursor: pointer;
    > div {
      line-height: 25px;
    }
  }
}
