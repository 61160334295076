.highlighted {
  border: 2px solid $primary;
  position: relative;
  padding: 0px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(82, 186, 152, 0.12);
    z-index: 1030;
  }
}
.no-highlight {
  border: 2px solid transparent;
  padding: 0px;
}

.dark-overlay {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(23, 23, 23, 0.12) 0, rgba(23, 23, 23, 0.45) 100%);
    z-index: 1;
  }
}
