// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
  user-select: none;
  border: $btn-border-width solid transparent;
  letter-spacing: 0.025em;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  @include hover() {
    color: $body-color;
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    @include box-shadow($btn-active-box-shadow);

    &:focus {
      @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
    }
  }
  &.btn-social {
    display: flex;
    justify-content: center;
    margin-top: 0.75rem;
    margin-left: 0px !important;
    width: 100%;
    align-items: center;
    overflow: hidden;
    padding: 1rem 1.25rem 1rem 56px;
    > .btn-inner--icon {
      width: 10%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 100%;
      min-width: 56px;
      svg {
        width: 21px;
        height: 21px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: 0;
      }
    }
    > .btn-inner--text {
      flex: 1 1;
      margin-left: 0px !important;
    }
    + .btn-block {
      margin-top: 0.75rem;
      margin-left: 0px !important;
    }
  }
}
.text-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: $btn-block-spacing-y;
  margin-left: 0px !important;
  width: 100%;
  align-items: center;
}
.btn-back {
  width: 29px;
  height: 34px;
  padding: 5px;
  cursor: pointer;
  > svg {
    width: 100%;
  }
}

.embed-btn-back {
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;
  z-index: 1035;
  left: 5px;
  top: 20px;
}
@media (max-width: 300px) {
  .embed-btn-back {
    border: none !important;
    .fas {
      font-size: 1.3rem;
    }
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

//
// Button Sizes
//

.btn-xl {
  @include button-size(
    $btn-padding-y-xl,
    $btn-padding-x-xl,
    $btn-font-size-xl,
    $btn-line-height-xl,
    $btn-border-radius-xl
  );
}

.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $btn-font-size-lg !important,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-md {
  @include button-size(
    $btn-padding-y-md,
    $btn-padding-x-md,
    $btn-font-size-md,
    $btn-line-height-md,
    $btn-border-radius-md
  );
}

.btn-sm {
  @include button-size(0, $btn-padding-x-sm, $btn-font-size-sm, 2.2, $btn-border-radius-sm);
}

//
// Block button
//

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
    margin-left: 0px !important;
  }
}

.google-icon {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
}

.linkedin-icon {
  width: 20px;
  height: 20px;
  margin-top: -4px;
}
.facebook-icon {
  width: 22px;
  height: 22px;
  margin-top: -4px;
}

.btn-facebook-white,
.btn-facebook-white:hover {
  border: 1px solid $facebook;
  color: $facebook;
}
.btn-linkedin-white,
.btn-linkedin-white:hover {
  border: 1px solid $linkedin;
  color: $linkedin;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.paypal-button {
  background-color: rgb(255, 196, 57);
  color: rgb(0, 0, 0);
  border: 0px solid rgb(0, 0, 0);
  border-radius: 50px;
  max-width: none;
  width: 100%;
  font-size: 0;
  padding: 0 0.6rem;
  line-height: 38px;
  span {
    font-size: 0.92rem;
    line-height: 38px;
    display: inline-block;
    margin-right: 8px;
  }
  div {
    position: relative;
    height: 20px;
    display: inline-block;
    vertical-align: middle;

    svg {
      vertical-align: top;
      width: auto;
    }
  }
}
.card-icon {
  fill: #dee2e6;
  width: 26px;
  > path {
    fill: #dee2e6;
  }
}

[class|='confetti'] {
  position: absolute;
}

$colorscon: (#d13447, #ffbf00, #263672);

@for $i from 0 through 300 {
  $w: random(8);
  $l: random(100);
  .confetti-#{$i} {
    width: #{$w}px;
    height: #{$w * 0.4}px;
    background-color: nth($colorscon, random(3));
    top: -10%;
    left: unquote($l + '%');
    opacity: random() + 0.5;
    transform: rotate(#{random() * 360}deg);
    animation: drop-#{$i} unquote(4.5 + random() + 's') unquote(random() + 's') 2;
  }

  @keyframes drop-#{$i} {
    100% {
      top: 110%;
      left: unquote($l + random(15) + '%');
    }
  }
}
