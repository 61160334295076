.video-wrapper {
  &.session {
    .video-player {
      display: flex;
      flex-direction: column;
      position: relative;
      .video-normal-view {
        height: 100%;
        overflow: hidden;
        background: $gray-800;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        .raise-hands {
          position: absolute;
          width: 50px;
          height: 50px;
          background: $primary;
          border-radius: 25px;
          left: 20px;
          top: 140px;
          box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
          padding: 8px;
          cursor: pointer;

          svg {
            fill: $white;
          }
        }

        .videos-header-view {
          height: 100px;
          overflow: hidden;
          position: relative;

          .video-slider-back,
          .video-slider-forward {
            background: none;
            border: none;
            padding: 0rem 0.75rem;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background: $gray-800;

            &:hover {
              background: $gray-900;
            }

            svg {
              height: 100%;
              fill: $white;
              height: 22px;
            }
          }

          .video-slider-forward {
            left: auto;
            right: 0;
          }

          .video-slider-container {
            height: 100%;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: center;
            flex: 0 0 auto;
            overflow: auto;
            scroll-behavior: smooth;
            padding-bottom: 17px;
            box-sizing: content-box;

            .video-slider-loop-container {
              justify-content: center;
              display: flex;
              position: relative;
              left: 46px;
              padding-right: 46px;

              .video-slider-loop {
                height: 100%;
                white-space: nowrap;
                position: relative;
                display: flex;

                .video-item {
                  width: 175px;
                  min-width: 175px;
                  height: 100px;
                  position: relative;
                  box-sizing: border-box;
                  border: 3px solid transparent;
                  flex: 0 0 auto;
                  cursor: pointer;
                  transition: all 0.1s ease-in-out;
                  border-radius: 0.6rem;
                  overflow: hidden;
                  margin-right: 3px;

                  &:hover {
                    border: 3px solid $primary;
                  }

                  .main-video-view {
                    height: 100%;
                    width: 100%;
                    position: relative;

                    img {
                      object-fit: cover;
                      width: 100%;
                      height: 100%;
                    }
                  }

                  &:hover {
                    border: 3px solid $primary;
                  }

                  .video-item-toolbar {
                    position: absolute;
                    bottom: 5px;
                    left: 5px;
                    padding: 0rem 0.6rem 0 0.3rem;
                    border-radius: 0.6rem;
                    line-height: 1.8;
                    font-size: 80%;
                    background: rgba(33, 37, 41, 0.75);
                    color: $white;
                    box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.08);
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    max-width: 92%;

                    svg {
                      height: 16px;
                      fill: $white;
                    }

                    .video-user-name {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    }
                  }
                  .raise-hands {
                    top: 5px;
                    left: 5px;
                    width: 25px;
                    height: 25px;
                    padding: 0 4px;
                  }
                }
              }
            }
          }
        }

        > .main-video-view {
          position: relative;
          height: calc(100% - 166px);
          width: auto;
          padding: 10px;

          .main-video-container {
            position: relative;
            border: 3px solid transparent;
            cursor: pointer;
            transition: all 0.1s ease-in-out;
            border-radius: 0.6rem;
            overflow: hidden;
            width: 100%;
            height: 100%;

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
            }

            &:hover {
              border: 3px solid $primary;
            }
            &.presenter-view {
              overflow: scroll;
              iframe {
                pointer-events: none !important;
                height: 30000px !important;
              }
            }
          }
        }
      }

      .video-collage-view {
        height: 100%;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        margin: auto;
        justify-content: center;
        flex: 0 0 auto;
        background: #2f3136;
        padding-bottom: 56px;
        overflow: hidden;

        .video-collage-container {
          justify-content: center;
          display: flex;
          position: relative;
          overflow: auto;
          scroll-behavior: smooth;
          box-sizing: content-box;
          padding-right: 17px;
          margin-right: -17px;

          .video-slider-loop-container {
            justify-content: center;
            display: flex;
            position: relative;

            .video-collage-loop {
              height: 100%;
              position: relative;
              display: inline-flex;
              flex-wrap: wrap;
              align-items: stretch;
              justify-content: center;
              gap: 6px;

              .video-item {
                max-width: 100%;
                height: auto;
                position: relative;
                border: 3px solid #2f3136;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
                overflow: hidden;
                align-items: center;
                display: flex;
                border-radius: 0.6rem;
                overflow: hidden;

                &:hover {
                  border: 3px solid $primary;
                }

                .main-video-view {
                  position: relative;
                  cursor: pointer;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;

                  img {
                    height: auto;
                    object-fit: cover;
                    width: 100%;
                    max-width: 100%;
                  }
                }
                .raise-hands {
                  position: absolute;
                  background: $primary;
                  border-radius: 25px;
                  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
                  cursor: pointer;
                  top: 5px;
                  left: 5px;
                  width: 30px;
                  height: 30px;
                  padding: 2px 5px;
                  svg {
                    fill: $white;
                  }
                }

                .video-item-toolbar {
                  position: absolute;
                  bottom: 5px;
                  left: 5px;
                  padding: 0rem 0.6rem 0 0.3rem;
                  border-radius: 0.6rem;
                  line-height: 1.8;
                  font-size: 80%;
                  background: rgba(33, 37, 41, 0.75);
                  color: $white;
                  box-shadow: 0 0 0.5rem rgba(136, 152, 170, 0.08);
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  max-width: 92%;

                  svg {
                    height: 16px;
                    fill: $white;
                  }

                  .video-user-name {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                }
              }
            }
          }
        }
      }

      .video-footer-view {
        display: flex;
        flex-direction: row;
        height: 55px;
        background: $gray-900;
        padding: 3px 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;

        .video-buttons-group {
          display: flex;
          flex-direction: row;
          align-items: center;

          .button-item {
            background: none;
            border: none;
            padding: 0rem 0.75rem;
            border-radius: 0.6rem;
            color: $white;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .button-icon {
              height: 24px;
              position: relative;
              display: block;
              font-size: 0;
              text-align: center;

              svg {
                height: 100%;
                fill: $white;
              }
              i {
                height: 100%;
                color: $white;
                font-size: 17px;
                line-height: 24px;
              }
            }

            .button-item-name {
              line-height: 1.2;
              font-size: 80%;
            }

            &:hover {
              background: rgba(255, 255, 255, 0.125);
            }

            &.active {
              background: rgba(255, 255, 255, 0.25);
            }

            &.end-call {
              background: #bf2117;
              height: 45px;
              margin-left: 5px;
            }
          }

          .button-item-split {
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 100%;

            .button-item {
              &:first-child {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                padding-right: 0.525rem;
              }

              &:nth-child(2) {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                padding: 0rem 0.217rem;
                height: auto;

                &:nth-child(2):hover {
                  background: rgba(255, 255, 255, 0.45);
                }
              }

              a {
                padding: 0px;

                .button-icon-expand {
                  height: 18px;
                  position: relative;
                  display: block;
                  font-size: 0;
                  text-align: center;

                  svg {
                    height: 100%;
                    fill: $white;
                  }
                }
              }
            }

            &:hover .button-item {
              background: rgba(255, 255, 255, 0.25);
            }
          }

          .dropdown-menu {
            text-align: left;
            list-style: none;
            background-color: #fff;

            .dropdown-item {
              color: #525f7f;

              > svg {
                fill: #525f7f;
                height: 20px;
                margin: 0;
                vertical-align: -19%;
                margin-right: 3px;
              }

              .fas.hide {
                opacity: 0;
              }

              &.documents,
              &.white-board,
              &.share-screen {
                display: none;
              }
            }
          }
        }
      }
      &:hover .button-item {
        background: rgba(255, 255, 255, 0.25);
      }
    }
    .dropdown-menu {
      text-align: left;
      list-style: none;
      background-color: #fff;
      .dropdown-item {
        color: #525f7f;

        > svg {
          fill: #525f7f;
          height: 20px;
          margin: 0;
          vertical-align: -19%;
          margin-right: 3px;
        }
        .fas.hide {
          opacity: 0;
        }
        &.documents,
        &.white-board,
        &.share-screen {
          display: none;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .video-wrapper.session {
    .video-player {
      .video-footer-view {
        padding: 3px 5px;

        .video-buttons-group {
          .button-item-split {
            .button-item {
              padding-right: 0.25rem !important;
            }
          }

          .button-item {
            padding: 0rem 0.5rem;

            &.white-board,
            &.documents {
              display: none !important;
            }
          }

          .dropdown-menu {
            .dropdown-item {
              &.documents,
              &.white-board {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .video-wrapper.session {
    .video-player {
      .video-footer-view {
        .filler:nth-child(4) {
          display: none;
        }

        .video-buttons-group {
          .button-item {
            &.share-screen {
              display: none;
            }
          }

          .dropdown-menu {
            .dropdown-item {
              &.share-screen {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.view-bottom-bar {
  position: absolute;
  bottom: 13px;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  background: transparent;
  pointer-events: none;
  padding: 0 13px;
  button {
    border-radius: 0 0 0 0;
    pointer-events: auto;
    font-size: 17px;
  }
}
