.rbt {
  height: auto !important;
  padding: 0 !important;

  .rbt-input-multi {
    height: auto !important;
    padding: 0.875rem 1rem;
    min-height: calc(2.25em + 1.25rem + 5px);
    .rbt-input-wrapper {
      .rbt-input-main {
        font-size: 1.0625rem !important;
        line-height: 1.8;
      }
      .rbt-token {
        display: inline-flex;
        height: 100%;
        background: $primary;
        color: $white;
        border-radius: $btn-border-radius;
        padding: 0 $btn-padding-x-sm 0;
        font-weight: 600;
        line-height: 1.8;
        margin-right: 5px;
        margin-bottom: 5px;
        button,
        button:hover {
          opacity: 1 !important;
          span {
            color: $white;
          }
        }
      }
    }
  }
  .rbt-menu-custom-option {
    .rbt-highlight-text {
      display: inline-block;
      font-weight: $font-weight-bold;
      color: $white;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      -webkit-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      letter-spacing: 0.025em;
      padding: 0.525rem 1.05rem;
      font-size: 0.92rem;
      line-height: 1.5;
      border-radius: 1.5rem;
      color: #fff;
      background-color: $primary;
      border-color: $primary;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      margin-left: 0.5rem;
    }
  }
}
#invitations-sent {
  .rbt-input-multi {
    border: none;
    padding: 0px;
    min-height: 0px !important;
    .rbt-input-wrapper {
      .rbt-token-disabled {
        opacity: 0.85;
      }
      > div:not(.rbt-token-disabled) {
        height: 0px !important;
      }
    }
  }
}
