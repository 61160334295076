// stylelint-disable property-blacklist, declaration-no-important

//
// Border
//

.border {
  border: $border-width solid $border-color !important;
}
.border-top {
  border-top: $border-width solid $border-color !important;
}
.border-right {
  border-right: $border-width solid $border-color !important;
}
.border-bottom {
  border-bottom: $border-width solid $border-color !important;
}
.border-left {
  border-left: $border-width solid $border-color !important;
}

.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}

@each $color, $value in $theme-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}

.border-white {
  border-color: $white !important;
}

.border-transparent {
  border-color: transparent !important;
  + .form-control-feedback {
    opacity: 0;
  }
}
.border-transparent:hover {
  border-color: $input-border-color !important;
  + .form-control-feedback {
    opacity: 1;
  }
}

.border-transparent:focus {
  border-color: $input-focus-border-color !important;
  + .form-control-feedback {
    opacity: 1;
  }
}

//
// Border-radius
//

.rounded-sm {
  border-radius: $border-radius-sm !important;
}

.rounded {
  border-radius: $border-radius-sm !important;
}

.rounded-top {
  border-top-left-radius: $border-radius-sm !important;
  border-top-right-radius: $border-radius-sm !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-right {
  border-top-right-radius: $border-radius-sm !important;
  border-bottom-right-radius: $border-radius-sm !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-bottom {
  border-bottom-right-radius: $border-radius-sm !important;
  border-bottom-left-radius: $border-radius-sm !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-left {
  border-top-left-radius: $border-radius-sm !important;
  border-bottom-left-radius: $border-radius-sm !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-lg {
  border-radius: $border-radius-lg !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: $rounded-pill !important;
}

.rounded-0 {
  border-radius: 0 !important;
}
.rounded-top-left {
  border-top-left-radius: $border-radius-sm !important;
}
.rounded-bottom-left {
  border-bottom-left-radius: $border-radius-sm !important;
}
.rounded-top-right {
  border-top-right-radius: $border-radius-sm !important;
}
.rounded-bottom-right {
  border-bottom-right-radius: $border-radius-sm !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .border-#{$breakpoint}-left-none {
      border-left: none !important;
    }
    .border-#{$breakpoint}-right-none {
      border-right: none !important;
    }
    .border-#{$breakpoint}-top-none {
      border-top: none !important;
    }
    .border-#{$breakpoint}-bottom-none {
      border-bottom: none !important;
    }
    .border-#{$breakpoint}-bottom-auto {
      border-bottom: $border-width solid $border-color !important;
    }
    .border-#{$breakpoint}-top-auto {
      border-top: $border-width solid $border-color !important;
    }
    .border-#{$breakpoint}-right-auto {
      border-right: $border-width solid $border-color !important;
    }
    .border-#{$breakpoint}-left-auto {
      border-left: $border-width solid $border-color !important;
    }
  }
}
