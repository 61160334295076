.media {
  display: flex;
  align-items: flex-start;
  i {
    margin-left: 0.5rem;
    vertical-align: middle;
  }
}

.media-body {
  flex: 1;
}
