.daterangepicker {
  &:before,
  &:after {
    right: 23px;
  }
  &:after {
    border-bottom: 6px solid rgba(255, 255, 255, 1) !important;
  }
  .applyButton {
    background: rgb(82, 186, 152) !important;
  }
  .cancelButton,
  .applyButton {
    font-size: 14px !important;
  }
  .rangecontainer {
    border-right: 1px solid $gray-200;
    > div {
      color: #32325d !important;
      line-height: 1.8;
      border: none !important;
      text-align: left;
      border-radius: $input-border-radius !important;
    }
  }
  .fromDateTimeContainer {
    margin: 0px !important;
    padding: 15px;
    width: 325px !important;
    font-size: 14px !important;
    .fromDateHourContainer {
      border: none !important;
      .inputDate {
        border-radius: $input-border-radius !important;
      }
      .timeContainer {
        display: none;
      }
    }
    .monthYearContainer {
      margin: 15px 0px 20px !important;
      .multipleContentOnLine {
        padding: 0 !important;
        select {
          padding: 5px 10px;
        }
      }
    }
    .activeNotifier {
      opacity: 0;
    }
  }
}
