.wizard-breadcrumbs {
  width: 100%;
  padding-bottom: $breadcrumb-padding-y;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: $breadcrumb-wizard-color;
  font-size: $breadcrumb-wizard-font-size;
  line-height: 140%;
  border-bottom: none;
  overflow: hidden;
  line-height: 100%;
  position: relative;
  margin: 20px 0;
  font-family: $breadcrumb-wizard-font-family;

  a {
    background: transparent;
    border: 0;
    font-size: $breadcrumb-wizard-font-size;
    color: $breadcrumb-wizard-color;
    font-weight: $font-weight-normal;
    line-height: 100%;
    position: relative;
    line-height: 38px;
    span {
      background: $breadcrumb-wizard-bg;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: $white;
      font-size: $breadcrumb-wizard-font-size;
      font-weight: $font-weight-extra-bold;
      text-align: center;
      line-height: 100%;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  a:not(:last-of-type) {
    padding-right: 30px;
  }
  a.active {
    color: $breadcrumb-wizard-bg-active;
  }
  a.disabled {
    cursor: auto;
  }
  a:not(:last-of-type):after {
    content: '';
    width: 10px;
    height: 10px;
    border-width: 3px 3px 0 0;
    border-style: solid;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 10px;
    border-radius: 3px;
    top: 15px;
  }
  a.active span {
    background: $breadcrumb-wizard-bg-active;
  }
}

.completion-score {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 40px;
  font-family: $breadcrumb-wizard-font-family;
  .fit-progress-bar {
    background-color: $breadcrumb-wizard-bg;
    height: 9px;
    width: 100%;
    border: 0;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    .fill {
      content: '';
      background-color: $breadcrumb-wizard-bg-active;
      display: inline-block;
      height: 100%;
      border-radius: 3px;
      position: absolute;
      left: 0;
      z-index: 1;
      -webkit-transition: width 0.2s;
      transition: width 0.2s;
    }
    &.incomplete .fill {
      background-color: #f5a623;
    }
  }
  .text {
    font-size: $breadcrumb-wizard-font-size;
    line-height: 30px;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .wizard-breadcrumbs .col-xl-8 {
    display: none !important;
  }
}
