//
// Avatar group
//

// General styles

.avatar-group {
  .avatar {
    position: relative;
    z-index: 2;
    border: 2px solid $card-bg;
    display: inline-block;
    vertical-align: top;
    line-height: 32px;
    text-align: center;
    padding: 0;
    &:hover {
      z-index: 3;
    }
    img {
      vertical-align: top;
    }
  }

  .avatar + .avatar {
    margin-left: -1rem;
  }
}
