.chat-room {
  .chat-message {
    max-width: 95%;
    padding: $input-padding-y $input-padding-x;
    border-radius: $border-radius-sm;
    border-top-left-radius: 0;
    background: $white;
    margin-bottom: 15px;
    box-shadow: $box-shadow-sm;
    > p {
      font-size: $font-size-sm;
    }
    &.me {
      border-top-left-radius: $border-radius-sm;
      border-top-right-radius: 0;
      margin-left: auto;
      background: #eefff9;
    }
  }

  .chat-form {
    input {
      padding-left: 40px;
    }
    .btn {
      right: 0;
      bottom: 0;
      border-radius: 0.6rem;
      box-shadow: none;
      height: 100%;
      border: 1px solid transparent;
      &.emoji-btn {
        right: auto;
        left: 0;
        background: transparent;
        padding: 0 10px;
        box-shadow: none;
        color: $gray-500;
        i {
          font-size: 19px;
          vertical-align: -10%;
        }
        &:active {
          box-shadow: none;
        }
        &.active {
          box-shadow: none;
          color: $primary;
        }
      }
    }
  }
  .emoji-mart {
    position: relative;
    .emoji-mart-scroll {
      max-height: calc(100vh - 415px);
    }
  }
}
