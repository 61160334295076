//
// Dropzone
//

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
}

.dz-message {
  background-color: $input-bg;
  border: $input-border-width dashed $input-border-color;
  border-radius: $input-border-radius;
  text-align: center;
  color: $text-muted;
  transition: $transition-base;
  order: -1;
  cursor: pointer;
  z-index: 999;

  &:hover {
    border-color: $text-muted;
    color: $body-color;
    .dz-button:after {
      background-color: #52ba98;
      color: #fff;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }
}

.dz-drag-hover .dz-message {
  border-color: theme-color('primary');
  color: theme-color('primary');
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: fade-out($black, 0.1);
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $input-border-radius;
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $input-border-radius;
}

.dz-preview-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: $input-border-radius;
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

[data-dz-size] strong {
  font-weight: $font-weight-normal;
}
.dropzone .dz-button {
  background: transparent;
  color: $default !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dz-message {
  height: 192px;
  display: flex;
  justify-content: center;
}
.dropzone-single.dz-max-files-reached .dz-message:hover .dz-button {
  color: $white !important;
}

.dz-button:after {
  content: 'Browse Files';
  padding: 0.45rem 0.9rem;
  font-size: 0.85rem;
  line-height: 1.5;
  border-radius: 1.5rem;
  color: #52ba98;
  background-color: transparent;
  border: 1px solid #52ba98;
  margin-top: 0.9rem;
}
