.w-0 {
  width: 0 !important;
}
.mw-100 {
  max-width: 100% !important;
  width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
  min-height: 100% !important;
}
.mh-400 {
  max-height: 100% !important;
  min-height: 400px !important;
}
.mh-checkout {
  max-height: 560px !important;
}
.mh-checkout-subscription {
  max-height: 695px !important;
}
// Viewport additional helpers

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: calc(100vh - 50px) !important;
}
.min-vh-100-50 {
  min-height: calc(100vh - 50px) !important;
}
.min-vh-100-100 {
  min-height: calc(100vh - 100px) !important;
}
.min-vh-100-150 {
  min-height: calc(100vh - 150px) !important;
}
.min-vh-100-200 {
  min-height: calc(100vh - 200px) !important;
}
.min-vh-100-300 {
  min-height: calc(100vh - 300px) !important;
}
.min-vh-100-400 {
  min-height: calc(100vh - 400px) !important;
}
.vh-100 {
  height: 100vh !important;
}
.vh-100-50 {
  height: calc(100vh - 50px) !important;
}
.vh-100-75 {
  height: calc(100vh - 75px) !important;
}
.vh-100-100 {
  height: calc(100vh - 100px) !important;
}
.vh-100-105 {
  height: calc(100vh - 105px) !important;
}
.vh-100-150 {
  height: calc(100vh - 105px) !important;
}
.vh-100-155 {
  max-height: calc(100vh - 155px) !important;
}
.mh-100-105 {
  max-height: calc(100vh - 105px) !important;
}
.min-vh-100-42 {
  min-height: calc(100vh - 42px);
}
.min-w-200 {
  min-width: 200px;
}
@include media-breakpoint-down(sm) {
  .min-vh-100-100-sm {
    min-height: calc(100vh - 100px) !important;
  }
  .mh-100-105 {
    height: calc(100vh - 50px) !important;
    max-height: 100% !important;
    min-height: 100% !important;
  }
}
@include media-breakpoint-up(sm) {
  .mh-auto-sm {
    max-height: 100% !important;
    min-height: 100% !important;
  }
  .mh-50-sm {
    max-height: 50px !important;
  }
  .mh-90-sm {
    max-height: 90px !important;
  }
  .mw-24-sm {
    max-width: 24px;
    width: 100% !important;
  }
  .mw-30-sm {
    max-width: 30px;
    width: 100% !important;
  }
  .mw-36-sm {
    max-width: 36px;
    width: 100% !important;
  }
  .mw-42-sm {
    max-width: 42px;
    width: 100% !important;
  }
  .mw-48-sm {
    max-width: 48px;
    width: 100% !important;
  }
  .vw-90-sm {
    max-width: 90vw;
    width: 100% !important;
  }
  .vw-95-sm {
    max-width: 95vw;
    width: 100% !important;
  }
  .vw-100-sm {
    max-width: 100vw;
    width: 100% !important;
  }
  .mw-1100-sm {
    max-width: 1100px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1050-sm {
    max-width: 1050px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1000-sm {
    max-width: 1000px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-950-sm {
    max-width: 950px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-900-sm {
    max-width: 900px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-850-sm {
    max-width: 850px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-800-sm {
    max-width: 800px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-750-sm {
    max-width: 750px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-700-sm {
    max-width: 700px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-600-sm {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-650-sm {
    max-width: 650px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-550-sm {
    max-width: 550px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-500-sm {
    max-width: 500px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-450-sm {
    max-width: 450px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-415-sm {
    max-width: 415px !important;
    min-width: 415px !important;
    width: 100%;
  }
  .mw-400-sm {
    max-width: 400px !important;
    width: 100%;
    display: inline-block !important;
  }

  .mw-350-sm {
    max-width: 350px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-300-sm {
    max-width: 300px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-250-sm {
    max-width: 250px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-200-sm {
    max-width: 200px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-150-sm {
    max-width: 150px !important;
    width: 100%;
    display: inline-block !important;
  }
  .min-h-100-sm {
    min-height: 100vh !important;
  }
  .min-h-600-sm {
    min-height: 600px !important;
  }
  .min-h-550-sm {
    min-height: 550px !important;
  }
  .min-h-500-sm {
    min-height: 500px !important;
  }
  .min-h-450-sm {
    min-height: 450px !important;
  }
  .min-h-400-sm {
    min-height: 400px !important;
  }
  .min-h-350-sm {
    min-height: 350px !important;
  }
  .min-h-300-sm {
    min-height: 300px !important;
  }
  .min-h-250-sm {
    min-height: 250px !important;
  }
  .min-h-200-sm {
    min-height: 200px !important;
  }
  .min-h-150-sm {
    min-height: 150px !important;
  }
  .max-h-250-sm{
      max-height: 250px !important;  
    }    
  .vw-100-300-sm {
    max-width: calc(100vw - 300px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-350-sm {
    max-width: calc(100vw - 350px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-400-sm {
    max-width: calc(100vw - 400px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-450-sm {
    max-width: calc(100vw - 450px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-500-sm {
    max-width: calc(100vw - 500px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-550-sm {
    max-width: calc(100vw - 550px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-600-sm {
    max-width: calc(100vw - 600px) !important;
    width: 100%;
    display: inline-block !important;
  }
    
  .col-collapse-sm {
    &.left {
      flex: 0 0 calc(100vw - 50px);
      max-width: calc(100vw - 50px);
      &.edit {
        flex: 0 0 calc(100vw - 350px);
        max-width: calc(100vw - 350px);
      }
    }
    &.right {
      flex: 0 0 50px;
      max-width: 50px;
    }
  }
}
@include media-breakpoint-up(md) {
  .mh-auto-md {
    max-height: 100% !important;
    min-height: 100% !important;
  }
  .mh-50-md {
    max-height: 50px !important;
  }
  .mh-90-md {
    max-height: 90px !important;
  }
  .mw-24-md {
    max-width: 24px;
    width: 100% !important;
  }
  .mw-30-md {
    max-width: 30px;
    width: 100% !important;
  }
  .mw-36-md {
    max-width: 36px;
    width: 100% !important;
  }
  .mw-42-md {
    max-width: 42px;
    width: 100% !important;
  }
  .mw-48-md {
    max-width: 48px;
    width: 100% !important;
  }
  .vw-90-md {
    max-width: 90vw;
    width: 100% !important;
  }
  .vw-95-md {
    max-width: 95vw;
    width: 100% !important;
  }
  .vw-100-md {
    max-width: 100vw;
    width: 100% !important;
  }
  .mw-600-md {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-550-md {
    max-width: 550px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-500-md {
    max-width: 500px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-450-md {
    max-width: 450px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-400-md {
    max-width: 400px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-350-md {
    max-width: 350px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-300-md {
    max-width: 300px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-250-md {
    max-width: 250px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-200-md {
    max-width: 200px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-150-md {
    max-width: 150px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1100-md {
    max-width: 1100px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1050-md {
    max-width: 1050px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1000-md {
    max-width: 1000px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-950-md {
    max-width: 950px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-900-md {
    max-width: 900px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-850-md {
    max-width: 850px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-800-md {
    max-width: 800px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-750-md {
    max-width: 750px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-700-md {
    max-width: 700px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-600-md {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-650-md {
    max-width: 650px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-550-md {
    max-width: 550px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-500-md {
    max-width: 500px !important;
    width: 100%;
    display: inline-block !important;
  }    
  .min-h-600-md {
    min-height: 600px !important;
  }
  .min-h-550-md {
    min-height: 550px !important;
  }
  .min-h-500-md {
    min-height: 500px !important;
  }
  .min-h-450-md {
    min-height: 450px !important;
  }
  .min-h-400-md {
    min-height: 400px !important;
  }
  .min-h-350-md {
    min-height: 350px !important;
  }
  .min-h-300-md {
    min-height: 300px !important;
  }
  .min-h-250-md {
    min-height: 250px !important;
  }
  .min-h-200-md {
    min-height: 200px !important;
  }
  .min-h-150-md {
    min-height: 150px !important;
  }
  .min-h-100-md {
    min-height: 100vh !important;
  }
  .vw-100-300-md {
    max-width: calc(100vw - 300px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-350-md {
    max-width: calc(100vw - 350px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-400-md {
    max-width: calc(100vw - 400px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-450-md {
    max-width: calc(100vw - 450px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-500-md {
    max-width: calc(100vw - 500px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-550-md {
    max-width: calc(100vw - 550px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-600-md {
    max-width: calc(100vw - 600px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .col-collapse-md {
    &.left {
      flex: 0 0 calc(100vw - 50px);
      max-width: calc(100vw - 50px);
    }
    &.right {
      flex: 0 0 50px;
      max-width: 50px;
    }
  }
}
@include media-breakpoint-up(lg) {
  .mh-auto-lg {
    max-height: 100% !important;
    min-height: 100% !important;
  }
  .mh-50-lg {
    max-height: 50px !important;
  }
  .mh-90-lg {
    max-height: 90px !important;
  }
  .mw-24-lg {
    max-width: 24px;
    width: 100% !important;
  }
  .mw-30-lg {
    max-width: 30px;
    width: 100% !important;
  }
  .mw-36-lg {
    max-width: 36px;
    width: 100% !important;
  }
  .mw-42-lg {
    max-width: 42px;
    width: 100% !important;
  }
  .mw-48-lg {
    max-width: 48px;
    width: 100% !important;
  }
  .vw-90-lg {
    max-width: 90vw;
    width: 100% !important;
  }
  .vw-95-lg {
    max-width: 95vw;
    width: 100% !important;
  }
  .vw-100-lg {
    max-width: 100vw;
    width: 100% !important;
  }
  .mw-1100-lg {
    max-width: 1100px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1050-lg {
    max-width: 1050px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1000-lg {
    max-width: 1000px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-950-lg {
    max-width: 950px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-900-lg {
    max-width: 900px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-850-lg {
    max-width: 850px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-800-lg {
    max-width: 800px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-750-lg {
    max-width: 750px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-700-lg {
    max-width: 700px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-600-lg {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-650-lg {
    max-width: 650px !important;
    width: 100%;
    display: inline-block !important;
  }    
  .mw-600-lg {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-550-lg {
    max-width: 550px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-500-lg {
    max-width: 500px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-450-lg {
    max-width: 450px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-400-lg {
    max-width: 400px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-350-lg {
    max-width: 350px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-300-lg {
    max-width: 300px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-250-lg {
    max-width: 250px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-200-lg {
    max-width: 200px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-150-lg {
    max-width: 150px !important;
    width: 100%;
    display: inline-block !important;
  }
  .min-h-600-lg {
    min-height: 600px !important;
  }
  .min-h-550-lg {
    min-height: 550px !important;
  }
  .min-h-500-lg {
    min-height: 500px !important;
  }
  .min-h-450-lg {
    min-height: 450px !important;
  }
  .min-h-400-lg {
    min-height: 400px !important;
  }
  .min-h-350-lg {
    min-height: 350px !important;
  }
  .min-h-300-lg {
    min-height: 300px !important;
  }
  .min-h-250-lg {
    min-height: 250px !important;
  }
  .min-h-200-lg {
    min-height: 200px !important;
  }
  .min-h-150-lg {
    min-height: 150px !important;
  }
  .min-h-100-lg {
    min-height: 100vh !important;
  }
  .vw-100-300-lg {
    max-width: calc(100vw - 300px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-350-lg {
    max-width: calc(100vw - 350px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-400-lg {
    max-width: calc(100vw - 400px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-450-lg {
    max-width: calc(100vw - 450px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-500-lg {
    max-width: calc(100vw - 500px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-550-lg {
    max-width: calc(100vw - 550px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-600-lg {
    max-width: calc(100vw - 600px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .col-collapse-lg {
    &.left {
      flex: 0 0 calc(100vw - 50px);
      max-width: calc(100vw - 50px);
    }
    &.right {
      flex: 0 0 50px;
      max-width: 50px;
    }
  }
}
@include media-breakpoint-up(xl) {
  .mh-auto-xl {
    max-height: 100% !important;
    min-height: 100% !important;
  }
  .mh-50-xl {
    max-height: 50px !important;
  }
  .mh-90-xl {
    max-height: 90px !important;
  }
  .mw-24-xl {
    max-width: 24px;
    width: 100% !important;
  }
  .mw-30-xl {
    max-width: 30px;
    width: 100% !important;
  }
  .mw-36-xl {
    max-width: 36px;
    width: 100% !important;
  }
  .mw-42-xl {
    max-width: 42px;
    width: 100% !important;
  }
  .mw-48-xl {
    max-width: 48px;
    width: 100% !important;
  }
  .vw-90-xl {
    max-width: 90vw;
    width: 100% !important;
  }
  .vw-95-xl {
    max-width: 95vw;
    width: 100% !important;
  }
  .vw-100-xl {
    max-width: 100vw;
    width: 100% !important;
  }
  .mw-1220-xl {
    max-width: 1220px !important;
    width: 100%;
  }
  .mw-1400-xl {
    max-width: 1400px !important;
    width: 100%;
  }
  .mw-1100-xl {
    max-width: 1100px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1050-xl {
    max-width: 1050px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-1000-xl {
    max-width: 1000px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-950-xl {
    max-width: 950px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-900-xl {
    max-width: 900px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-850-xl {
    max-width: 850px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-800-xl {
    max-width: 800px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-750-xl {
    max-width: 750px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-700-xl {
    max-width: 700px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-600-xl {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-650-xl {
    max-width: 650px !important;
    width: 100%;
    display: inline-block !important;
  }  
  .mw-600-xl {
    max-width: 600px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-550-xl {
    max-width: 550px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-500-xl {
    max-width: 500px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-450-xl {
    max-width: 450px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-400-xl {
    max-width: 400px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-350-xl {
    max-width: 350px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-300-xl {
    max-width: 300px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-250-xl {
    max-width: 250px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-200-xl {
    max-width: 200px !important;
    width: 100%;
    display: inline-block !important;
  }
  .mw-150-xl {
    max-width: 150px !important;
    width: 100%;
    display: inline-block !important;
  }
  .min-h-600-xl {
    min-height: 600px !important;
  }
  .min-h-550-xl {
    min-height: 550px !important;
  }
  .min-h-500-xl {
    min-height: 500px !important;
  }
  .min-h-450-xl {
    min-height: 450px !important;
  }
  .min-h-400-xl {
    min-height: 400px !important;
  }
  .min-h-350-xl {
    min-height: 350px !important;
  }
  .min-h-300-xl {
    min-height: 300px !important;
  }
  .min-h-250-xl {
    min-height: 250px !important;
  }
  .min-h-200-xl {
    min-height: 200px !important;
  }
  .min-h-150-xl {
    min-height: 150px !important;
  }
  .min-h-100-xl {
    min-height: 100vh !important;
  }
  .vw-100-300-xl {
    max-width: calc(100vw - 300px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-350-xl {
    max-width: calc(100vw - 350px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-400-lg {
    max-width: calc(100vw - 400px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-450-xl {
    max-width: calc(100vw - 450px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-500-xl {
    max-width: calc(100vw - 500px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-550-xl {
    max-width: calc(100vw - 550px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .vw-100-600-xl {
    max-width: calc(100vw - 600px) !important;
    width: 100%;
    display: inline-block !important;
  }
  .col-collapse-xl {
    &.left {
      flex: 0 0 calc(100vw - 50px);
      max-width: calc(100vw - 50px);
    }
    &.right {
      flex: 0 0 50px;
      max-width: 50px;
    }
  }
}

.scroll,
.scroll-y {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
  display: none;
}

.scroll-x {
  overflow-x: auto;
}
.readmore-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  /* "transparent" only works here because == rgba(0,0,0,0) */
  background-image: linear-gradient(to bottom, transparent, white);
}
