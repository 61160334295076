//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  height: $card-height;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  scroll-behavior: smooth;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group:first-child {
    .list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .list-group:last-child {
    .list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }
  &.register-card {
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  // Workaround for the image size bug in IE
  // See: https://github.com/twbs/bootstrap/pull/28855
  min-height: 1px;
  padding: $card-spacer-x;
  color: $card-color;
  position: relative;
}
.or-vertical {
  border-right: 1px solid #dee2e6;
  padding: 10px 15px;

  &:after {
    position: absolute;
    content: 'or';
    right: -7px;
    background: #f7fafc;
    width: auto;
    line-height: 20px;
    top: 12px;
  }
}

@include media-breakpoint-down(sm) {
  .col-sm-12.or-vertical {
    border-top: 1px solid #dee2e6;
    border-right: none;
    padding: 20px 15px 0px;
    margin-top: 25px;
    text-align: center;
    &:after {
      position: absolute;
      content: 'or';
      display: inline-block;
      right: auto;
      background: #f7fafc;
      line-height: 20px;
      text-align: center;
      width: 32px;
      left: calc(50% - 16px);
      top: -10px;
    }
  }
}

.card-thumbnail {
  width: 100%;
  height: 210px;
  object-fit: cover;
}

.card-title {
  margin-bottom: $card-spacer-y;
}

.card-subtitle {
  margin-top: -$card-spacer-y / 2;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  @include hover() {
    text-decoration: none;
  }

  + .card-link {
    margin-left: $card-spacer-x;
  }
}

//
// Optional textual caps
//

.card-header {
  padding: $card-spacer-y $card-spacer-x;
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: $card-cap-color;
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .list-group {
    .list-group-item:first-child {
      border-top: 0;
    }
  }
}

.card-footer {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}

//
// Header navs
//

.card-header-tabs {
  margin-right: -$card-spacer-x / 2;
  margin-bottom: -$card-spacer-y;
  margin-left: -$card-spacer-x / 2;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -$card-spacer-x / 2;
  margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
  @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}

//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-blacklist
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-blacklist
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}

//
// Accordion
//

.accordion {
  > .card {
    overflow: hidden;

    &:not(:last-of-type) {
      border-bottom: 0;
      @include border-bottom-radius(0);
    }

    &:not(:first-of-type) {
      @include border-top-radius(0);
    }

    > .card-header {
      @include border-radius(0);
      margin-bottom: -$card-border-width;
    }
  }
}

// card-profile-img

.card-profile-image {
  position: relative;
  height: 140px;
  max-width: 140px;
  width: 100%;
  display: block;
  margin: 0 auto;
  img {
    top: 50%;
    max-width: 140px;
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 70px !important;
  }
  &.small {
    max-width: 65px;
    height: 65px;
    img {
      height: 65px;
      max-width: 65px;
      width: 65px;
    }
  }
}

.card-profile-item {
  text-decoration: none;
}
// Thank You Page Message
.thankyou-message {
  a {
    white-space: normal;
  }
}

// Sidebar Card

#sidebar {
  .card {
    overflow-y: scroll;
    position: absolute;
    height: 100%;
    .card-header {
      .back-button {
        position: absolute;
        height: 100%;
        top: 0;
        padding: 0 0.7rem;
        border-radius: 0;
        box-shadow: none;
      }
      .back-button:hover {
        transform: none;
        box-shadow: none;
      }
    }
    .card-footer {
      position: fixed;
      bottom: 0;
      z-index: 1000;
      box-shadow: -0.2rem -0.2rem 0.8rem 0rem rgba(136, 152, 170, 0.15);
    }
  }
}

// Profile Embed Card

.profile-embed-card {
  position: relative;
  height: 160px;
  border: 1px solid $gray-300 !important;
  .card-description {
    position: relative;
    display: block;
    height: calc(1.80625rem * 2);
    overflow: hidden;
    &:after {
      content: '';
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 1rem;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 1));
    }
    p {
      margin: 0;
    }
  }

  .arrow-right {
    position: absolute;
    display: block;
    text-align: right;
    bottom: 15px;
    right: 15px;
    i {
      font-size: 25px;
    }
  }
}

.or-horizontal:before {
  content: 'OR';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0px auto;
  top: -12px;
  background: $white;
  width: 45px;
  text-align: center;
  font-size: 0.8rem;
}
@media (max-width: 991px) {
  .card-booking {
    box-shadow: none !important;
  }
}

.bot-chat-bubble {
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  padding: 10px;
  margin-left: 75px;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.2);
  margin-top: 75px;
  animation: reveal-bot-chat-bubble 1.5s cubic-bezier(0.68, -0.24, 0.37, 1.33) 0s 1;
  transform-origin: -10% 2%;
  top: 0;
  max-width: 340px;
  &:before {
    content: '';
    position: absolute;
    z-index: 10;
    left: -47px;
    top: 20px;
    width: 65px;
    height: 47px;
    border-style: solid;
    border-width: 0 40px 3px 0;
    border-color: #fff;
    background: transparent;
    border-bottom-right-radius: 105px 75px;
    border-top-left-radius: 50px 70px;
    display: block;
    transform: rotate(85deg);
  }
  &.close-bot {
    width: 350px;
    transform: scale(0);
    opacity: 0;
    animation: close-bot-chat-bubble 0.4s cubic-bezier(0.68, -0.24, 0.37, 1.33) 0s 1;
  }
}
.btn-bot-controller {
  animation: reveal-bot-controller 0.7s cubic-bezier(0.68, -0.24, 0.37, 1.33) 0s 1;
  position: relative;
  top: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: -5px;
  &.close-bot-controller {
    transform: translate(-100%, 0%);
    animation: close-bot-controller 0.3s cubic-bezier(0.68, -0.24, 0.37, 1.33) 0s 1;
  }
}
.bot-img {
  width: 100px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  animation: reveal-bot-img 0.7s ease-in-out 0s 1;
  &.close-bot {
    transform: translate(-108%, 5%) rotate(-60deg);
    animation: close-bot-img 1s ease-in-out 0s 1;
  }
}

@keyframes reveal-bot-img {
  0% {
    transform: translate(-108%, 5%) rotate(-60deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
@keyframes reveal-bot-controller {
  0% {
    transform: translate(-100%, 0%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes close-bot-controller {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0%);
  }
}

@keyframes reveal-bot-chat-bubble {
  0% {
    width: 350px;
    transform: scale(0);
    opacity: 0;
  }
  75% {
    width: 350px;
    transform: scale(0);
    opacity: 0;
  }
  100% {
    width: 370px;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes close-bot-img {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  75% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(-108%, 5%) rotate(-60deg);
  }
}
@keyframes close-bot-chat-bubble {
  0% {
    width: 370px;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    width: 350px;
    transform: scale(0);
    opacity: 0;
  }
}
@media (max-width: 410px) {
  .bot-chat-bubble {
    max-width: calc(100% - 20px);
    margin-left: 20px;
    margin-top: 90px;
    &:before {
      left: -15px;
      top: -5px;
      width: 65px;
      transform: rotate(96deg);
      z-index: 0;
    }
    .btn {
      width: 100% !important;
      display: block !important;
    }
  }
}
