.opacity-0 {
  opacity: 0;
}
.opacity-15 {
  opacity: 0.15;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-100 {
  opacity: 1;
}
