#packages {
  overflow: hidden;
  ul.benefits {
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      i {
        color: $primary;
        font-size: $font-size-sm;
        line-height: 20px;
        margin-right: 0.25rem;
      }
    }
  }
}

.nav-tabs {
  display: flex;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
  min-width: 605px;
  &.tabs-alternative {
    .nav-item {
      flex: 0;
      border: none;

      .nav-link {
        line-height: 3rem;
        padding: 0 $input-padding-x;
        border-bottom: 3px solid $white;
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        border-bottom: 3px solid $primary;
      }
    }
    ~ .tab-content {
      .tab-pane.active {
        height: 100%;
        > .card {
          height: calc(100% - 50px);
          position: absolute;
          right: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
  .nav-item {
    flex: 1;
    cursor: pointer;
    border-right: 1px solid $nav-tabs-border-color;
    text-transform: capitalize;

    .nav-link {
      width: 100%;
      line-height: 3.5rem;
      padding: 0;
      display: inline-block;
      font-family: $btn-font-family;
      font-weight: $btn-font-weight;
      text-align: center;
      white-space: $btn-white-space;
      vertical-align: middle;
      cursor: poiner;
      border: $btn-border-width solid transparent;
      background: $white;
      border-bottom: 3px solid $white;
      color: $default;
      font-size: $tabs-font-size;

      &.disabled {
        color: $nav-link-disabled-color;
        background-color: transparent;
        border-color: transparent;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      border-bottom: 6px solid $primary;
      color: $primary;
      background: $white;
    }
    &.nav-small {
      border-right: none;
      flex: initial;
      height: 39px;
      .nav-link {
        line-height: 35px;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        color: $gray-700;
        font-weight: 500;
        font-size: $tabs-font-size;
        white-space: nowrap;
        &:hover {
          border-bottom: 3px solid $gray-400;
        }
      }
      .nav-link.active,
      .nav-item.show .nav-link {
        border-bottom: 3px solid $primary;
        color: $primary;
        border-radius: 0;
      }
    }
    .dropdown-menu {
      // Make dropdown border overlap tab border
      margin-top: -$nav-tabs-border-width;
      // Remove the top rounded corners here since there is a hard edge above the menu
      @include border-top-radius(0);
    }
  }
  .nav-item:last-child {
    border-right: none;
  }
}
.tab-content {
  p.text-sm {
    ul {
      li {
        a {
          color: #525f7f;
        }
      }
    }
  }
}
.tab-pane {
  .header {
    text-transform: capitalize;
  }
}
