.bg-gradient-landing {
  background: linear-gradient(180deg, white 50%, #f7fafc 100%);
}
.bg-gradient-landing-reverse {
  background: linear-gradient(180deg, #f7fafc 0%, white 100%);
}

.bg-video {
  overflow: hidden;
  background: #b69b67;
  img {
    object-fit: cover;
    min-width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
}
.bg-black-gradient {
  background: linear-gradient(to bottom, rgba(23, 23, 23, 0.45) 0, rgba(23, 23, 23, 0.7) 100%);
  .text-white {
    text-shadow: 2px 2px 8px rgba(17, 17, 17, 0.1);
  }
}

@include media-breakpoint-up(lg) {
  .video-wrapper.landing {
    padding-bottom: 66.3vh;
  }
}
@include media-breakpoint-up(md) {
  .position-fixed-md {
    position: fixed !important;
  }
}
