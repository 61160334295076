// stylelint-disable declaration-no-important

.shadow-sm {
  box-shadow: $box-shadow-sm !important;
}
.shadow {
  box-shadow: $box-shadow !important;
}
.shadow-lg {
  box-shadow: $box-shadow-lg !important;
}
.shadow-bottom {
  box-shadow: $box-shadow-bm !important;
}
.shadow-top {
  box-shadow: $box-shadow-tp !important;
}
.shadow-right {
  box-shadow: $box-shadow-rt !important;
}
.shadow-left {
  box-shadow: $box-shadow-lt !important;
}
.shadow-none {
  box-shadow: none !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    .shadow-#{$breakpoint}-none {
      box-shadow: none !important;
    }
    .shadow-#{$breakpoint}-auto {
      box-shadow: $box-shadow !important;
    }
    .shadow-#{$breakpoint}-sm {
      box-shadow: $box-shadow-sm !important;
    }
    .shadow-#{$breakpoint}-lg {
      box-shadow: $box-shadow-lg !important;
    }
    .shadow-#{$breakpoint}-bottom {
      box-shadow: $box-shadow-bm !important;
    }
    .shadow-#{$breakpoint}-top {
      box-shadow: $box-shadow-tp !important;
    }
    .shadow-#{$breakpoint}-right {
      box-shadow: $box-shadow-rt !important;
    }
    .shadow-#{$breakpoint}-left {
      box-shadow: $box-shadow-lt !important;
    }
  }
}
