.inline-text_copy {
  display: block;
}
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  padding: 0;
  background: none;
  border: none;
  //  border-bottom: 1px dashed rgb(233, 236, 239);
  outline: none;
  line-height: 1.8;
  text-align: left;
}

.inline-text_copy--active {
  cursor: pointer;
  position: relative;
  padding: $input-padding-y-lg $input-padding-x;
  i {
    margin-left: 0.7rem;
    font-size: 0.8rem;
    display: inline-block;
    float: right;
    transform: translateY(65%);
    opacity: 0.5;
  }
  &:hover i {
    opacity: 1;
  }
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border: 1px solid #666666;
  padding: $input-padding-y $input-padding-x;
  text-align: left;
  border-radius: $input-border-radius;
  width: 100%;
}
