// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: $navbar-padding-y $navbar-padding-x;
  height: 50px;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properties so that content nested within behave properly.
  %container-flex-properties {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .container,
  .container-fluid {
    @extend %container-flex-properties;
  }

  @each $breakpoint, $container-max-width in $container-max-widths {
    > .container#{breakpoint-infix($breakpoint, $container-max-widths)} {
      @extend %container-flex-properties;
    }
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.navbar-brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  @include font-size($navbar-brand-font-size);
  line-height: inherit;
  white-space: nowrap;
  svg {
  }
  @include hover-focus() {
    text-decoration: none;
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.navbar-nav {
  display: flex;
  flex-direction: column; // cannot use `inherit` to get the `.navbar`s value
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: 0;
    padding-left: 0;
    position: relative;
    color: $gray-700;
    &.nav-link-dropdown:after,
    &.nav-link-dropdown:before {
      content: '';
      top: 2.2rem;
      position: absolute;
      width: 0.5rem;
      height: 0.1rem;
      background-color: $gray-700;
      display: inline-block;
    }
    &.nav-link-dropdown:before {
      right: 0.65rem;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &.nav-link-dropdown:after {
      right: 0.3rem;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .dropdown-menu {
    position: static;
    float: none;
    z-index: 1035;
  }
}

.navbar-dark {
  .nav-link {
    &.nav-link-dropdown:after,
    &.nav-link-dropdown:before {
      background-color: $white;
    }
  }
}

// Navbar text
//
//

.navbar-text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
// on the `.navbar` parent.
.navbar-collapse {
  //  flex-basis: 100%;
  //  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;
}

.filler {
  flex: 1 1 auto;
}

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  @include font-size($navbar-toggler-font-size);
  line-height: 1;
  background-color: transparent; // remove default button style
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus() {
    text-decoration: none;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        %container-navbar-expand-#{$breakpoint} {
          padding-right: 0;
          padding-left: 0;
        }

        > .container,
        > .container-fluid {
          @extend %container-navbar-expand-#{$breakpoint};
        }

        @each $size, $container-max-width in $container-max-widths {
          > .container#{breakpoint-infix($size, $container-max-widths)} {
            @extend %container-navbar-expand-#{$breakpoint};
          }
        }
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .navbar-nav {
          flex-direction: row;

          .dropdown-menu {
            position: absolute;
          }

          .nav-link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        %container-nesting-#{$breakpoint} {
          flex-wrap: nowrap;
        }

        > .container,
        > .container-fluid {
          @extend %container-nesting-#{$breakpoint};
        }

        @each $size, $container-max-width in $container-max-widths {
          > .container#{breakpoint-infix($size, $container-max-widths)} {
            @extend %container-nesting-#{$breakpoint};
          }
        }

        .navbar-collapse {
          display: flex !important; // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          //          flex-basis: auto;
          width: 100%;
        }

        .navbar-toggler {
          display: none;
        }
      }
    }
  }
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

// Dark links against a light background
.navbar-light {
  .navbar-brand {
    color: $navbar-light-brand-color;

    @include hover-focus() {
      color: $navbar-light-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $gray-800;

      @include hover-focus() {
        color: $navbar-light-hover-color;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-light-color;
    border-color: $navbar-light-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-light-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-light-color;
    a {
      color: $navbar-light-active-color;

      @include hover-focus() {
        color: $navbar-light-active-color;
      }
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-brand {
    color: $navbar-dark-brand-color;

    @include hover-focus() {
      color: $navbar-dark-brand-hover-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: $gray-800;

      @include hover-focus() {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: $navbar-dark-color;
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: escape-svg($navbar-dark-toggler-icon-bg);
  }

  .navbar-text {
    color: $navbar-dark-color;
    a {
      color: $navbar-dark-active-color;

      @include hover-focus() {
        color: $navbar-dark-active-color;
      }
    }
  }
}

.nav-dropdown {
  display: none;
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 50px;
  width: 100vw;
  border-top: 1px solid $input-border-color;
  > ul {
    display: -ms-flexbox;
    display: flex;
  }
  .mega-nav-box {
    width: 180px;
    padding: 30px 8px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    list-style: none;

    img {
      width: 140px;
      height: 105px;
      border-radius: 8px;
      display: block;
    }
    .image-shadow {
      -webkit-filter: brightness(98%);
      filter: brightness(98%);
    }

    .mega-nav-copy {
      padding-top: 1rem;
    }
    .title {
      font-size: $font-size-base;
      font-weight: 500;
      color: $default;
      padding-bottom: 0.2rem;
    }
    .mega-description {
      font-size: $font-size-sm;
      line-height: $line-height-sm;
      display: inline-block;
    }
    &.nav-extras {
      padding-top: 20px;
      font-weight: 500;
      margin-right: 40px;
      ul {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 70%;
        padding: 0;
        li {
          padding: 0.43rem 0;
          list-style: none;
          line-height: $line-height-sm;
          a {
            font-size: $font-size-sm;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    > ul {
      padding-left: 4rem;
    }
    > ul > .mega-nav-box {
      margin-right: 40px;
    }
  }
}

.nav-item:hover .nav-dropdown {
  display: flex;
}

.zindex-1 {
  z-index: 1 !important;
}
